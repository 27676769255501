// extracted by mini-css-extract-plugin
export var header = "MainMenu-module--header--52be1";
export var logoItem = "MainMenu-module--logoItem--bf5f0";
export var logoItemCollapsed = "MainMenu-module--logoItemCollapsed--1dbca";
export var logoLink = "MainMenu-module--logoLink--dd1f6";
export var menuContainer = "MainMenu-module--menuContainer--a1f76";
export var menuContainerCollapsed = "MainMenu-module--menuContainerCollapsed--c72b2";
export var menuItem = "MainMenu-module--menuItem--70e14";
export var menuItemActive = "MainMenu-module--menuItemActive--111b6";
export var mobileMenuIcon = "MainMenu-module--mobileMenuIcon--5771f";
export var mobileNavigationHidden = "MainMenu-module--mobileNavigationHidden--17e43";
export var mobileNavigationShow = "MainMenu-module--mobileNavigationShow--b3aa2";
export var navigation = "MainMenu-module--navigation--7c8c5";
export var navigationCollapsed = "MainMenu-module--navigationCollapsed--2a31e";