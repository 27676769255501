import React, { useState } from "react";
import { Link } from "gatsby";
import clsx from 'clsx';

import logo from './../../images/logo.png'; 
import mobileMenu from './../../images/mobile-menu.svg' 
import closeMenu from './../../images/mobile-close.svg' 
import homeIcon from './../../images/home-icon.png'

import { 
    header, 
    menuContainer, 
    menuContainerCollapsed,
    menuItem, 
    menuItemActive, 
    navigation, 
    navigationCollapsed, 
    logoLink, 
    logoItem, 
    logoItemCollapsed,
    mobileMenuIcon,
    mobileNavigationShow,
    mobileNavigationHidden
} from './MainMenu.module.scss'
import useOffset from "../../lib/useOffset";
import { useEffect } from "react";

const links = [
    {
        name: 'CHAIRS',
        url: '/chairs'
    },
    {
        name: 'TABLES',
        url: '/tables'
    },
    {
        name: 'WOODEN DETAILS',
        url: '/details'
    },
    {
        name: 'ABOUT US',
        url: '/about'
    },
    {
        name: 'CONTACT',
        url: '/contact'
    },
]

const MainMenu = ({ path }) => {
    const shouldCollapse = useOffset(80);
    const [showMobileMenu, setShowMobileMenu] = useState(false);

    const isHomepage = path === '/';

    useEffect(() => {
        setShowMobileMenu(false);
    }, [path])

    const menuClass = isHomepage && !shouldCollapse ? menuContainer : menuContainerCollapsed;
    const navigationClass = isHomepage && !shouldCollapse ? navigation : navigationCollapsed;
    const logoClass = isHomepage && !shouldCollapse ? logoItem : logoItemCollapsed;

    const handleShowMobileMenu = () => {
        setShowMobileMenu(!showMobileMenu);
    }

    return (
        <div className={menuClass}>
            <section className={header}> 
                <a className={logoLink} href='/'>
                    <img className={logoClass} src={logo}/>
                </a>
                <span className={mobileMenuIcon} onClick={handleShowMobileMenu}>
                    {showMobileMenu ? (
                        <img src={closeMenu}/>
                    ) : (
                        <img src={mobileMenu}/>
                    )}
                </span>
                <nav className={clsx(navigationClass, {
                    [mobileNavigationHidden]: !showMobileMenu,
                    [mobileNavigationShow]: showMobileMenu,
                })}>
                    <Link to={'/'} className={menuItem} activeClassName={menuItemActive}>
                        <img src={homeIcon}/>
                    </Link>
                    {links.map((link, i) => {
                        return (
                            <Link key={i} to={link.url} className={menuItem} activeClassName={menuItemActive} data-text={link.name}>{link.name}</Link>
                            )
                        })}
                </nav>
            </section>
        </div>
    )
}

export default MainMenu