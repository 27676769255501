import { useCallback, useEffect, useState } from 'react';

// returns true if offset reached while scrolling down
const useOffset = (offset, margin = 0) => {
    const [offsetReached, setOffsetReached] = useState(false);

    const handleScroll = useCallback(() => {
        if (window.scrollY >= offset + margin) {
            setOffsetReached(true);
        } else if (window.scrollY < offset - margin) {
            setOffsetReached(false);
        }
    }, [offset, margin]);

    useEffect(() => {
        window.addEventListener('scroll', handleScroll, true);

        return () => {
            window.removeEventListener('scroll', handleScroll, true);
        };
    }, [handleScroll]);

    return offsetReached;
};

export default useOffset;
