import React from "react";

import { footer, footerWrap, footerColumns, footerColumn, footerColumnTitle, footerText, companyName } from './Footer.module.scss';

const Footer = () => {
  return (
    <div className={footerWrap}>
        <div className={footer}>
          <h3 className={companyName}>ZEG–WIL FURNITURE Sp. z o.o. Sp. K.</h3>
          <div className={footerColumns}>
            <div className={footerColumn}>
              <h3 className={footerColumnTitle}>Contact details</h3>
              <div className={footerText}>
                <span>Phone: +48 41 254 39 91</span>
                <span>ul.Langiewicza 192a,</span>
                <span>26-130 Suchedniów</span>
              </div>
            </div>
            <div className={footerColumn}>
              <h3 className={footerColumnTitle}>Location</h3>
              <iframe 
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d95462.0242713285!2d20.79442609723717!3d51.0428887988362!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x47183c6cbe78fce1%3A0xe4ceca7ccb5a7e8a!2sZeg-Wil.+Zak%C5%82ad+elektromechaniczny.+Wyr%C3%B3b+galanterii+drzewnej.+Zegad%C5%82o+W.!5e0!3m2!1spl!2spl!4v1444918664965" 
                width="100%" 
                height="260"
              />
            </div>
            
          </div>
        </div>
    </div>
  )
}

export default Footer