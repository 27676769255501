import React from "react"
import Footer from "../components/Footer"
import MainMenu from "../components/MainMenu"
import "./index.scss"

const container = { 
  minHeight: 1000,
}

export default function Layout({ children, location }) {


  
  console.log('PROPS', location);
    return (
      <>
        <MainMenu path={location.pathname}/>
        <div style={container}>
          {children}
        </div>
        <Footer/>
      </>
    )
  }